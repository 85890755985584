.downloadTemplate {
  text-decoration: unset;
  span {
    font-size: 17px;
    color: #1c75bc;
    text-decoration: underline;
  }
}
.noRecord {
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img {
    margin-bottom: 30px;
  }
}
.remarkIcon {
  background-color: unset;
  border: none;
  font-size: 16px;
  color: #1c75bc;
  &:hover {
    cursor: pointer;
  }
}
.workerName {
  background-color: unset;
  border: none;
  font-size: 16px;
  color: #1c75bc;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.tableContainer{
  table {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: separate;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  tr td:last-child {
    text-align: right;
  }
  th,
  td {
    padding: 12px;
    text-align: left;
    vertical-align: top;
    border-left: 1px solid #ddd !important;
  }
  td {
    border-top: 1px solid #ddd;
  }
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
}
.itemRowReverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;
  .filterButton {
    border: 1px solid #1c75bc;
    color: #1c75bc;
    font-size: 16px;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    padding: 11px;
    border-radius: 6.486px;
    &:hover {
      cursor: pointer;
    }
  }
}
