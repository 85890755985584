.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1c75bc !important;
}
.overrideCheckbox {
  color: white !important;
}
.MuiInput-root {
  position: relative;
  right: 28px;
  width: 247px;
}
