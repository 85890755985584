.multipleSelect {
  max-height: 341px !important;
  ul {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    li:first-child {
      padding-top: 8px;
    }
  }
}
.jobSelect {
  max-height: 341px !important;
  width: 372px;
  ul {
    padding-top: 0;
    display: flex;
    flex-direction: column;
    li:first-child {
      padding-top: 8px;
    }
  }
}