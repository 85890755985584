.tableContainer {
  table {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: separate;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  tr td:last-child {
    text-align: right;
  }
  th,
  td {
    padding: 12px;
    vertical-align: top;
    border-right: 1px solid #ddd !important;
  }
  td {
    border-top: 1px solid #ddd;
  }
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
}
.noRecord {
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 440px;
  justify-content: center;
  img {
    width: 130px;
    margin: 0px auto 30px auto;
  }
}
