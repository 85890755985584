.assignRecruiterModal .assignRecruiterBox {
  .header {
    color: #1c75bc;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0;
    }
  }
  .footer {
    margin-top: 30px;
    display: flex;
    flex-direction: row-reverse;
  }
}
.remarkModal .remarkBox {
  .header {
    color: #1c75bc;
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0;
    }
  }
  .footer {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    button {
      width: 184px;
    }
  }
}
.sendCommunicationModal .sendCommunicationBox {
  overflow: hidden;
  .textBox {
    border: 1px solid black;
    border-radius: 5px;
    background: #f1f1f1d6;
    padding: 15px;
  }
}
.sendJobCommunicationModal .sendJobCommunicationBox {
  .textBox {
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    background: #f9f9f9;
    padding: 0 15px;
    color: #757575;
    line-height: normal;
    p {
      margin: 10px 0;
    }
  }
}
.filterContainerDrawer {
  .FilterHeadingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: flex-end;
    .filterCloseArrow {
      margin-right: 20px !important;
      margin-left: 10px !important;
      border-radius: 20px !important;
      padding: 5px !important;
    }
    .filterHeading {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.04;
      letter-spacing: normal;
      text-align: left;
      color: #1c75c0;
    }
    .resetFiltersButton {
      font-size: 18px !important;
      font-weight: 600 !important;
      color: #707070 !important;
      text-decoration: underline !important;
      margin-right: 24px !important;
      text-transform: capitalize !important;
      &:hover {
        background-color: unset !important;
        text-decoration: underline;
      }
    }
  }
  .filterHeadingDivider {
    width: 100%;
    border-top: 1px solid #d5d5d5;
  }
  .filtersContainer {
    padding: 40px 30px;
    .singleFilterContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px !important;
      align-items: center;
      padding: 0 20px;
      .filterTitle {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
      }
    }
  }
  .filterButtonDividerContainer {
    position: absolute;
    bottom: 0px;
    position: sticky;
    background: white;
    padding-bottom: 25px;
  }
}

// .paperFollowUp {
//   border-radius: 0 !important;
//   max-width: 800px !important;
//   .downloadTemplate {
//     text-decoration: unset;
//     font-weight: 600;
//     span {
//       font-size: 17px;
//       color: #1c75bc;
//       text-decoration: underline;
//       margin-right: 10px;
//     }
//   }
//   .uploadButton {
//     color: #1c75bc;
//     border-color: #1c75bc;
//     margin-top: 10px;
//   }
//   .fileName {
//     max-width: 540px;
//     background-color: #1c75bc;
//     line-height: 19px;
//     border-radius: 14px;
//     padding: 7px 10px;
//     color: #ffffff;
//     font-size: 14px;
//     font-weight: 400;
//   }
// }

.FollowUpDialogContent {
  .FollowUpDate {
    align-items: center;
    display: flex;
    > span {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.82px;
      text-align: left;
      color: #757575;
    }
  }
  .FollowUpTime {
    align-items: center;
    display: flex;
    margin-top: 20px;
    > span {
      font-family: Nunito;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.82px;
      text-align: left;
      color: #757575;
    }
  }
  .datePicker {
    width: 180px;
    margin-left: 20px;
    input {
      padding: 12px;
    }
    button{
      padding: 2px;
    }
  }
  // .datePicker .MuiOutlinedInput-root {
  //   border-radius: 0 !important;
  // }
}
