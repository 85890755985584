.PrimaryColorButton{
  background-color: #2475bc !important;
}
.datePicker{
  width: 180px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker:first-child{
  margin-right: 20px;
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.bgvStatus{
  img{

    width: 14px;
    margin-right: 10px;
  }

}
.HoldSwitchContainer{
  justify-content: center;
  .holdButton{
    &:hover{
      cursor: pointer;
    }
    border: none;
    padding: .5rem 1rem;
    border-radius: .5rem;
    color: white;
    background-color: green;
    // font-size: 1.5rem;
  }
  .onHold{
    background-color: red;
  }
}
.DownloadReport{
  display: flex;
  button{
    border: none;
    background-color: transparent;
    font-weight: 500;
    text-decoration: underline;
    color: #1c75bc;
    font-size: 16px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
    &:disabled{
      color: #ccc;
      &:hover{
        cursor: none;
      }
    }

  }
}
.headingBGV{
  font-size: 24px;
  font-weight: 600;
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin:0 auto 30px;
    width: 90px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  margin-right: 30px;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}

.blueOutlinedButton{
  color: #1c75c0;
  text-transform: uppercase;
  border: 1px solid #1c75c0;
  padding: 12px;
  font-weight: bold;
  &:hover{
      cursor: pointer;
      transform: translateY(-2px);
  }
  
  &:focus{
      outline: unset;
  }
  &:active{
      transform: translateY(-1px);

  }

}
.blueFilledButton{
  background-color: #1c75c0;
  text-transform: uppercase;
  border: none;
  padding: 12px;
  color: white;
  font-weight: bold;
  &:hover{
      cursor: pointer;
      transform: translateY(-2px);
  }
  
  &:focus{
      outline: unset;
  }
  &:active{
      transform: translateY(-1px);

  }
  &:disabled{
    background-color: #d5d5d5;
    color: #999;
    transform: none;
    &:hover{
      cursor: inherit;
    }
  }

}

.FilterContainer{
  max-height: 100vh;
  overflow: auto;
}

.filterCloseArrow{
  margin-right: 20px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
  padding: 5px !important;
}
.FilterHeadingContainer{
  display:flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: flex-end;
}
.filterHeading{
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #1c75c0;
}
.resetFiltersButton{
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #707070 !important;
  text-decoration: underline !important;
  margin-right: 24px !important;
  text-transform: capitalize !important;
  &:hover{
      background-color: unset !important;
      text-decoration: underline;
  }
}
.filterHeadingDivider{
  width: 100%;
  border-top: 1px solid #d5d5d5;
}
.filtersContainer{
  padding: 40px 30px;
}
.singleFilterContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px !important;
  align-items: center;
  padding: 0 20px;
}
.filterTitle{
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
}
.filterButtonDividerContainer{
  position: absolute;
  bottom: 0px;
  background: white;
  padding-bottom: 25px;
}