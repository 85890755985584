.responseModal {
  .tableContainer {
    width: 95%;
    tr {
      width: 100%;
      td {
        padding-bottom: 22px;
      }
    }
  }
}
.paperResponse {
  width: 650px;
  border-radius: 0 !important;
  max-width: 800px !important;
  .summaryText {
    color: #1c75bc;
    font-weight: bold;
    font-size: 18px;
  }
  .mainRecord {
    font-size: 18px;
    font-weight: bold;
    margin: 33px 0 28px 0;
  }
}

.paperOnboarding {
  border-radius: 0 !important;
  max-width: 800px !important;
  .downloadTemplate {
    text-decoration: unset;
    font-weight: 600;
    span {
      font-size: 17px;
      color: #1c75bc;
      text-decoration: underline;
      margin-right: 10px;
    }
  }
  .uploadButton {
    color: #1c75bc;
    border-color: #1c75bc;
    margin-top: 10px;
  }
  .fileName {
    max-width: 540px;
    background-color: #1c75bc;
    line-height: 19px;
    border-radius: 14px;
    padding: 7px 10px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
  }
}

.onboardingDialog {
  .onboardingDialogTitle {
    padding: 0 0 24px 0;
    .downloadTemplate {
      text-decoration: unset;
      font-weight: 600;
      span {
        font-size: 17px;
        color: #1c75bc;
        text-decoration: underline;
        margin-right: 10px;
      }
    }
  }
  .onboardingDialogContent {
    padding: 24px 0 0 0;
    .fileName {
      max-width: 540px;
      background-color: #1c75bc;
      line-height: 19px;
      border-radius: 14px;
      padding: 7px 10px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .onboardingButton {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}

.historyModal .historyBox {
  .header {
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0;
    }
  }
  .userBox {
    border-radius: 10px;
    border: 0.5px solid rgba(105, 105, 105, 0.2);
    background: #fff;
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    .columnName {
      color: #696969;
      display: block;
      margin-bottom: 10px;
    }
    .columnValue {
      word-wrap: break-word;
    }
    .linkButton {
      background-color: unset;
      border: none;
      color: #1c75bc;
      padding: 0;
      font-size: 16px;
      text-decoration: underline;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .noRecord {
    font-family: Nunito;
    font-size: 32px;
    font-weight: 600;
    color: #707070;
    margin: 150px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 30px;
    }
  }
}

.profileVerificationModal .profileVerificationBox {
  .header {
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.286px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0;
      color: #2e2e2e;
    }
  }
  .userBox {
    display: flex;
    justify-content: space-between;
    .imgDiv {
      width: 315px;
      height: 317px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background: #000;
      position: relative;
      > span:first-child {
        border-radius: 42px;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 4px 8px;
        position: absolute;
        top: 6px;
        left: 6px;
      }
      img {
        width: inherit;
        height: inherit;
      }
    }
    .noImgDiv {
      width: 315px;
      height: 317px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-family: Nunito;
      font-size: 24px;
      font-weight: 600;
      color: #707070;
      position: relative;
      > span:first-child {
        border-radius: 42px;
        background: rgba(0, 0, 0, 0.4);
        color: #fff;
        font-family: Nunito;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 4px 8px;
        position: absolute;
        top: 6px;
        left: 6px;
      }
      img {
        width: 100px;
        margin-bottom: 12px;
      }
    }
  }
  .userButton {
    padding: 20px;
    > button:not(:first-child) {
      margin-left: 20px !important;
    }
  }
  .noRecord {
    font-family: Nunito;
    font-size: 32px;
    font-weight: 600;
    color: #707070;
    margin: 50px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 30px;
    }
  }
}

.userMemberModal .userMemberBox {
  .header {
    font-family: Nunito;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.286px;
    margin: 0;
    display: flex;
    justify-content: space-between;
    .closeButton {
      padding: 0;
      color: #2e2e2e;
      svg {
        font-size: 35px;
      }
    }
    .editBtn {
      background: #ffffff;
      border: 1px solid #1c75bc;
      border-radius: 5px;
      height: 43px;
      width: 85px;
      color: #1c75bc;
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      cursor: pointer;
      margin-right: 56px;
    }
  }
  .userBox {
    .outerGrid {
      padding: 14px 0 30px 0;
    }
    .innerGrid {
      padding-top: 30px;
      justify-content: space-between;
      .leftInnerGrid {
        > div:first-child {
          margin-bottom: 14px;
        }
      }
      .rightInnerGrid {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        > div:first-child {
          width: 95%;
          margin-bottom: 14px;
        }
      }
      .rightViewInnerGrid {
        padding-left: 20px;
        > div:first-child {
          width: 95%;
          margin-bottom: 14px;
        }
      }
      .formControl {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .textComponent,
        .textComponentHalf {
          input {
            max-height: 7px;
          }
          label {
            top: -7px;
          }
        }
        .textComponent {
          width: 100%;
        }
        .textComponentHalf {
          width: 48%;
        }
      }
    }
  }
  .userButton {
    padding: 20px;
  }
}

.deleteModalPaper {
  width: 452px;
  .boxTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    svg {
      border: 2px solid #ff0000;
      color: #ff0000;
      border-radius: 50%;
      font-size: 42px;
      margin-bottom: 15px;
    }
  }
  .closeButton {
    svg {
      font-size: 30px;
    }
  }
}
