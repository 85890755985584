.mainBox{
    // height: 113vh;
    background-color: white;
}

.BackNavigator{
    margin-top: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 10px;
}
.InputContainer{
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 5rem;
    margin-left: 38px;
    grid-row-gap:54px;
    margin-left: 60px;
    width: 50%;
    .InputBox{
        display: flex;
        flex-direction: row;
        row-gap: 16px;
        gap: 22%;
        border-bottom: 1px solid #d1cece;
        padding-bottom: 42px;
        p{
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            margin-top: -5px;
        }
    }
}

.labelBox{
    width: 30%;
}

.ageInputBox{
    font-family: Nunito;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    input{
        height: 32px;
        width: 138px;
        /* height: 44px; */
        border: 0.5px solid #806565;
        border-radius: 2px;
        padding-left: 10px;
        font-size: 18px;
    }
}

.InputContainer2{
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 5rem;
    margin-left: 38px;
    grid-row-gap:54px;
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 20px;
    margin-bottom: 60px;
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    .InputBox{
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        p{
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            margin-top: -5px;
        }
        .textAreaDesc{
            height: 119px !important;
            padding: 20px;
            font-family: Nunito;
            font-size: 16px;
            border-color: #D1D1D1;
            border-radius: 5px;
        }
        .textAreaDesc:focus{
            outline: none !important;
            border: 2px solid #1C75BC;
        }
    }
}

.browseFileLabel{
    color: #757575;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 14px;
    margin-bottom: 10px;
}

.browseFileContainer{
    margin-left: 12px;
    margin-bottom: 14px;
}

.jobDescLabel{
    margin-left: 59px;
    margin-top: 54px;
}

.selectedAttachment{
    color: #1C75BC;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}

.selectedAttachmentsContainer{
    width: 314px;
    height: 24px;
    flex-shrink: 0;
    display: flex;
    border-radius: 20px;
    background: #F1F1F1;
    justify-content: space-evenly;
    box-sizing: border-box;
    padding: 4px;
}

.fileCloseBtn{
    border: none;
    cursor: pointer;
}

.selectedAttachmentName{
    width: 314px;
    height: 24px;
    border-radius: 20px;
    background: #F1F1F1;
    color: #1C75BC;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    box-sizing: border-box;
    padding: 4px 135px 4px 19px;
}
.fileInputContainer {
    position: relative;
    display: inline-block;
  }

.attachmentBtnContainer{
    display: flex;
    align-items: baseline;
    gap: 22px;
}  
.formHeading{
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    margin-left: 15px;
}

.formFont{
    font-family: Nunito;
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.textComponent{
    input{
        max-height: 7px;
    }
    label{
        top: -7px;
    }
}
.saveCancel{
    // border-top: 1px solid red;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    padding: 30px 60px 25px 0;
    bottom: 0;
    right: 0;
    background: white;
    z-index: 2;
    border-top: 1px solid #b3aeae;
    margin-top: 50px;
    
}

.requiredField::after {
    content: "*";
    color: red;
}

.lightText{
    color: #757575;
    
}

.editBtn{
    background: #FFFFFF;
    border: 1px solid #1C75BC;
    border-radius: 5px;
    height: 43px;
    width: 85px;
    color: #1C75BC;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    position: absolute;
    right: 44px;
    top: 129px;
}