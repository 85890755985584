html,
body {
  background-color: #eeeeee;
}
::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.removeBorders {
  border: 0px !important;
}
.searchInstruction {
  font-family: "Nunito";
  font-style: italic;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;
  color: #afafaf;
}

.searchInstructionError {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #f14343;
}
.App {
  margin: 0;
}
.displayNone {
  display: none;
}
.overrideButtonHeightPaymentListing {
  margin-right: 20px;
  height: 55px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.flexDisplay {
  display: flex;
}
.flexDisplayColumn {
  display: flex;
  flex-direction: column;
}
.criminalRecord {
  background-color: #fcd2d1 !important;
}
.greenRow {
  background-color: rgb(32, 202, 32) !important;
}
.recSortOrderButton {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  margin-top: 8px !important;
  height: 35px !important;
  width: 35px;
}
.width200 {
  width: 200px;
}
.width270 {
  width: 270px;
}
.width80 {
  width: 80px;
}
.width300 {
  width: 300px;
}
.filterCLoseButton {
  height: 16px;
  width: 16px;
  margin-top: 6px !important;
  left: -30px;
}
.padding10 {
  padding: 10px;
}
.paddingTop8 {
  padding: 8px 0 0 0 !important;
}
.fontSize16 {
  font-size: 16px !important;
}
.fontSize14 {
  font-size: 14px !important;
}
.fontSize13 {
  font-size: 13px !important;
}
.fontSize12 {
  font-size: 12px !important;
}
.fontSize18 {
  font-size: 18px !important;
}
.fontSize20 {
  font-size: 20px !important;
}
.fontSize24 {
  font-size: 24px !important;
}
.fontSize32 {
  font-size: 32px !important;
}
.fontSize1_5rem {
  font-size: 1.5rem !important;
}
.fontSize1rem {
  font-size: 1rem !important;
}
.fontSize1_1rem {
  font-size: 1.1rem !important;
}
.fontSize_8rem {
  font-size: 0.8rem !important;
}
.fontWeightBold {
  font-weight: bold !important;
}
.fontWeightBolder {
  font-weight: bolder;
}
.fontWeight600 {
  font-weight: 600;
}
.textUnderline {
  text-decoration: underline;
}
.textAlignLeft {
  text-align: left;
}
.textAlignCenter {
  text-align: center;
}
.textAlignRight {
  text-align: right;
}
.padding2percent {
  padding: 2%;
  padding-right: 0%;
}
.padding8 {
  padding: 8px;
}
.paddingVertical6 {
  padding: 6px 0;
}
.paddingVertical10 {
  padding: 10px 0;
}
.margin20 {
  margin: 20px !important;
}
.margin10 {
  margin-right: 10px !important;
  margin-left: 20px !important;
}
.marginBottom40 {
  margin-bottom: 40px !important;
}
.marginBottom8 {
  margin-bottom: 8px !important;
}
.marginRight20 {
  margin-right: 20px !important;
}
.marginRight15 {
  margin-right: 15px;
}
.marginLeft15 {
  margin-left: 15px;
}
.marginVertical10 {
  margin: 10px 0 !important;
}
.paddingTop16 {
  padding-top: 16px;
}
.paddingBottom16 {
  padding-bottom: 16px;
}
.color707070 {
  color: #707070;
}
.dataButton {
  border-radius: 0px !important;
  background-color: #1c75bc !important;
  color: white !important;
  height: fit-content !important;
}
.dataButton:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.dataButtonNeo {
  border-radius: 5px !important;
  background-color: #1c75bc !important;
  color: white !important;
  display: flex;
  /* padding-top: 16px; */
  height: 78%;
  /* width: 14%; */
  width: 110px;
  text-transform: capitalize !important;
  text-wrap: nowrap !important;
  /* padding: 6px 8px; */
}
.dataButtonNeo:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.pennyNavbar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  /* background: blue; */
  /* height: fit-content; */
}
.dataButton2 {
  background-color: #1c75bc !important;
  color: white !important;
  height: 35px !important;
  margin-top: 8px !important;
}
.dataButton2:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.dataButtonOutline {
  border-radius: 0px !important;
  border: 1px solid #1c75bc !important;
  color: #1c75bc !important;
  height: fit-content !important;
  font-weight: bold !important;
}
.dataButtonOutline:hover {
  transform: translateY(-2px);
}
.dataButtonOutline:disabled {
  color: #7a7a7a !important;
  border: 1px solid #7a7a7a !important;
}
.dataButtonOutline2 {
  border: 1px solid #1c75bc !important;
  color: #1c75bc !important;
  height: fit-content !important;
  text-transform: capitalize !important;
}
.dataButtonOutline2:hover {
  transform: translateY(-2px);
}
.dataButtonOutline2:disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border: 1px solid rgba(0, 0, 0, 0.26) !important;
}
.blueColor {
  color: #1c75bc !important;
}
.blueColorOutlined {
  color: #1c75bc !important;
  border: 1px solid #1c75bc !important;
  height: fit-content !important;
}
.downloadExcelButton:hover {
  text-decoration: underline !important;
}
.disabledButton {
  background-color: #cccccc !important;
  color: #4f4f4f;
}
.profileRejectBtn {
  border-radius: 5px !important;
  border: 1px solid #ff3f3f !important;
  background: #fff;
  color: #ff3f3f !important;
  font-family: Nunito !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.profileApproveBtn {
  border-radius: 5px !important;
  border: 1px solid #1ecc2f !important;
  background: #1ecc2f !important;
  color: #fff !important;
  font-family: Nunito !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
.rdt_Table {
  width: max-content;
}
.rdt_TableHeadRow {
  background-color: #1c75bc !important;
}
.rdt_TableCol_Sortable {
  color: white !important;
  min-width: 150px;
}
.rdt_TableCell {
  border: 1px solid #dddddd;
}
.tabContainer {
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  border-top-left-radius: unset;
  padding: 15px;
}
.ReactTable .rt-thead.-header {
  background-color: #1c75bc !important;
  color: white !important;
  font-weight: bold;
}
.ReactTable .rt-thead .rt-tr {
  text-align: left !important;
}

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 1px #c3c3c3 !important;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid #c3c3c3 !important;
}
.stickCheckbox {
  position: sticky;
  left: 0;
  filter: drop-shadow(-15px 0px 0px #cfcfcf);
  outline: 1px solid #cfcfcf;
}
.stickCheckbox2 {
  position: sticky;
  left: 0;
  line-height: 1.4;
}
.stickInvigilatorName {
  position: sticky;
  left: 123px;
  line-height: 1.4;
}
.borderCheckbox {
  border-right: 1px solid #cfcfcf;
}
.row2HeaderShift {
  background-color: #daeeff !important;
  color: #2e2e2e !important;
  font-weight: 500;
}
.leftAlignLedgerTableRow {
  text-align: left !important;
}
.stickWorkerId {
  position: sticky;
  left: 0;
  border-right: 1px solid #1c75bc;
  outline: 1px solid #cfcfcf;
}
.stickWorkerIdnewMatch {
  position: sticky;
  left: 72px;
  border-right: 1px solid #1c75bc;
  outline: 1px solid #cfcfcf;
}
.put-border {
  outline-style: solid;
  outline-color: #ddd;
  outline-width: 1px;
}
.advanceStyle {
  background: #c9e7ff;
  height: 22px;
  font-weight: 500;
  padding: 2px 15px 2px 15px;
  border-radius: 47px;
  max-width: fit-content;
}
.widthFitContent {
  width: fit-content;
}
.noAdvanceStyle {
  height: 22px;
  font-weight: 500;
  padding: 2px 15px 2px 15px;
  border-radius: 47px;
  max-width: fit-content;
}
.Upper10Margin {
  margin: 10px 0px 0px 0px;
}
.Upper20Margin {
  margin: 10px 0px 0px 0px;
}
.UpperXMargin {
  margin: 7px 0px 0px 0px;
}
.stickWorkerName {
  position: sticky;
  left: 123px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameMatchNew {
  position: sticky;
  left: 73px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickRecruiterName {
  position: sticky;
  left: 57px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickRecruiterNameV2 {
  position: sticky;
  left: 0px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameV2 {
  position: sticky;
  left: 60px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickAttendanceWorkerName {
  position: sticky;
  max-width: 200px;
  line-height: 1.4;
}
.stickDriveZone {
  position: sticky;
  left: 86px;
  max-width: 200px;
  line-height: 1.4;
}
.stickWorkerName95 {
  position: sticky;
  left: 95px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickLeadId {
  position: sticky;
  left: 0;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickSNo {
  position: sticky;
  left: 0;
  min-width: 55px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.sortHeader {
  display: flex;
}
.sortHeaderDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.stickWorkerNameDb {
  position: sticky;
  left: 73px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickPaymentNameV2 {
  position: sticky;
  left: 60px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickOkayGoId {
  position: sticky;
  left: 0;
  filter: drop-shadow(1px 0px 0px #cfcfcf);
  outline: 1px solid #cfcfcf;
}
.stickPaymentNameV2Trans {
  position: sticky;
  outline: 1px solid #cfcfcf;
  left: 120px !important;
}

.stickWorkerIdShift {
  position: sticky;
  left: 0px;
  filter: drop-shadow(-15px 0px 0px #cfcfcf);
  max-width: 200px;
  line-height: 1.4;
}
.stickWorkerNameShift {
  position: sticky;
  left: 120px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameHub {
  position: sticky;
  left: -1px;
  max-width: 200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickApply {
  position: sticky;
  right: 0;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #1c75bc;
}
.header {
  top: 0;
  position: sticky;
  z-index: 540;
}
.header2 {
  top: 53px;
  position: sticky;
  z-index: 540;
}
.header3 {
  top: 57px;
  position: sticky;
  z-index: 540;
}
.long-header {
  top: 0;
  min-width: 172px;
  position: sticky;
  z-index: 540;
}
.zIndex1080 {
  z-index: 1060 !important;
}
.zIndex1060 {
  z-index: 1060 !important;
}
.width30 {
  width: 30px;
}
.zIndex540 {
  z-index: 540;
}
.zIndex-10 {
  z-index: -10;
}
.linkButton {
  color: #1c75bc !important;
  cursor: pointer !important;
  border: none !important ;
  background-color: unset !important;
  font-size: 14px !important;
  text-transform: uppercase;
}
.linkButton1 {
  color: #1c75bc !important;
  cursor: pointer !important;
  border: none !important ;
  background-color: unset !important;
  font-size: 18px !important;
}
.textAlignRight {
  text-align: right;
}
.linkButton:hover {
  text-decoration: underline !important;
}
.linkButton:focus {
  outline: none !important;
}
.marginLeft24 {
  margin-left: 24px !important;
}
.linkButton:active {
  margin-top: 5px !important;
}
.linkButton:disabled {
  color: #cccccc !important;
}
.fromDate {
  margin: 10px 0 10px 20px !important;
}
.toDate {
  margin: 0 0 10px 20px !important;
}
.borderStd {
  border: 1px solid #cccccc !important;
}
.margin0 {
  margin: 0 !important;
}
.marginTop16 {
  margin-top: 16px !important;
}
.justifyContentSpaceBetween {
  justify-content: space-between !important;
}
.justifyContentCenter {
  justify-content: center !important;
}
.jobDetail {
  font-family: nunito !important;
  height: 100%;
}
.filterButton {
  border-radius: 5px !important;
  border: 1px solid #cccccc !important;
  color: #4f4f4f !important;
  height: fit-content !important;
  padding: 5px !important;
  text-transform: none !important;
  width: auto;
}
.FilterHeading {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 20px;
}
.profilePhotoDiv {
  margin: 0 auto;
}
.profilePhoto {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin-top: 50px;
  margin-bottom: 25px;
}

.tabs {
  border-radius: 8px;
}
.tabs .Mui-selected {
  color: #1c75bc;
  text-decoration: underline;
}
.tabButtons {
  font-family: nunito !important;
  max-width: none !important;
  text-transform: none !important;
}
.basicDetails {
  padding: 0 20%;
}
.tabPanel {
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: 30px;
  padding: 40px;
}
.height40 {
  height: 40px;
}
.blueColorBorder {
  border: 1px solid #1c75bc !important;
  color: #1c75bc !important;
  background-color: rgba(28, 117, 188, 0.22) !important;
}
.greyColorBorder {
  border: 1px solid #cccccc !important;
  color: #333333 !important;
}
.statusButtons {
  border-radius: 11px !important;
  text-transform: inherit !important;
}
.statusDriveButtons {
  border-radius: 5px !important;
  text-transform: inherit !important;
}
.hrbpEditSelect {
  width: 240px;
  border-radius: 0px !important;
}
.hrbpEditSelect .MuiOutlinedInput-input {
  padding: 5px 5px 5px 10px !important;
}
.hrbpEditDatePicker .MuiOutlinedInput-root {
  border-radius: 0px !important;
}
.hrbpEditDatePicker .MuiIconButton-root {
  padding: 0px;
}
.hrbpEditInput {
  font-family: Nunito;
  width: 230px;
  height: 26px;
  padding-left: 5px;
  border: 1px solid #cccccc;
  font-size: 16px;
}
.blueButtonOutlined {
  color: #1c75bc !important;
  border: 1px solid #1c75bc !important;
}
.blueButtonContained {
  background-color: #1c75bc !important;
  color: #ffffff !important;
}
.redButtonContained {
  background-color: #f00 !important;
  color: #ffffff !important;
}
.nameLink {
  color: #1c75bc;
}
.nameLink_arrow {
  color: #1c75bc;
  text-decoration: none;
}
.invoiceLink {
  color: #1c75bc;
  text-decoration: none !important;
  font-weight: 600;
}
.prefferedPinWidth {
  max-width: 600px;
  word-wrap: break-word;
}

.DraftEditor-editorContainer {
  height: 190px !important;
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  overflow: hidden !important;
}

.MuiAutocomplete-endAdornment {
  margin-left: 10px !important;
}

.public-DraftEditor-content {
  height: 175px !important;
}

.displayNone {
  display: none;
}

.download_template_btn {
  color: #1c75bc !important;
  text-decoration: underline !important;
  text-transform: initial !important;
  font-size: inherit !important;
}
.download_template_btn:hover {
  cursor: pointer;
}
