.earningModal .earningBox {
  .header {
    font-family: Nunito;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 0;
    display: flex;
    justify-content: space-between;
    button {
      padding: 0;
    }
  }
  .earningStack {
    display: flex;
    flex-direction: row;
    position: relative;
    background: #f4f8fc;
    border-radius: 9px;
    padding: 32px 24px;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0;
    .financialYear {
      display: flex;
      align-items: center;
      width: 53%;
      justify-content: space-between;
      > svg {
        border-radius: 50%;
        background: #1c75bc;
        color: #ffffff;
        font-size: 16px;
        padding: 2px;
      }
      > span {
        margin: 0;
        font-family: Nunito;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .financialEarning {
      font-size: 18px;
      font-weight: 700;
      font-family: Nunito;
      font-style: normal;
      margin: 0;
      color: #1c75bc;
    }
  }
  .earningSelect {
    width: 100%;
    right: 0;
    color: #1c75bc;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 600;
    > svg {
      color: #1c75bc;
      top: calc(50% - 10px);
      font-size: 18px;
    }
  }
  .noRecord {
    font-family: Nunito;
    font-size: 26px;
    font-weight: 600;
    color: #707070;
    margin: 18px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    img {
      margin-bottom: 30px;
    }
  }
}
.filterContainerDrawer {
  .FilterHeadingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: flex-end;
    .filterCloseArrow {
      margin-right: 20px !important;
      margin-left: 10px !important;
      border-radius: 20px !important;
      padding: 5px !important;
    }
    .filterHeading {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.04;
      letter-spacing: normal;
      text-align: left;
      color: #1c75c0;
    }
    .resetFiltersButton {
      font-size: 18px !important;
      font-weight: 600 !important;
      color: #707070 !important;
      text-decoration: underline !important;
      margin-right: 24px !important;
      text-transform: capitalize !important;
      &:hover {
        background-color: unset !important;
        text-decoration: underline;
      }
    }
  }
  .filterHeadingDivider {
    width: 100%;
    border-top: 1px solid #d5d5d5;
  }
  .filtersContainer {
    padding: 40px 30px;
    .singleFilterContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px !important;
      align-items: center;
      padding: 0 20px;
      .filterTitle {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
      }
    }
  }
  .filterButtonDividerContainer {
    position: absolute;
    bottom: 0px;
    position: sticky;
    background: white;
    padding-bottom: 25px;
  }
}
