.MuiCheckbox-colorSecondary.Mui-checked {
  color: #1c75bc !important;
}

.overrideCheckbox {
  color: white !important;
}
.width25 {
  width: 25px;
}

/* mui css for dicrepency and filter grid */
.MuiGrid-grid-sm-5 {
  max-width: 40% !important;
}
.MuiGrid-grid-sm-3 {
  min-width: 27% !important;
}
