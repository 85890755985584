.filterContainerDrawer {
  .FilterHeadingContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: flex-end;
    .filterCloseArrow {
      margin-right: 20px !important;
      margin-left: 10px !important;
      border-radius: 20px !important;
      padding: 5px !important;
    }
    .filterHeading {
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.04;
      letter-spacing: normal;
      text-align: left;
      color: #1c75c0;
    }
    .resetFiltersButton {
      font-size: 18px !important;
      font-weight: 600 !important;
      color: #707070 !important;
      text-decoration: underline !important;
      margin-right: 24px !important;
      text-transform: capitalize !important;
      &:hover {
        background-color: unset !important;
        text-decoration: underline;
      }
    }
  }
  .filterHeadingDivider {
    width: 100%;
    border-top: 1px solid #d5d5d5;
  }
  .filtersContainer {
    padding: 40px 30px;
    .singleFilterContainer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px !important;
      align-items: center;
      padding: 0 20px;
      .filterTitle {
        font-size: 15px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #303030;
      }
    }
  }
  .filterButtonDividerContainer {
    position: absolute;
    bottom: 0px;
    position: sticky;
    background: white;
    padding-bottom: 25px;
  }
}
.onboardingDialog {
  .drivePaper {
    padding: 25px;
  }
  .workerPaper {
    padding: 25px;
    max-width: 900px;
  }
  .onboardingDialogTitle {
    padding: 0 0 14px 0;
    h6 {
      label {
        padding-top: 14px;
      }
    }
  }
  .onboardingDialogContent {
    padding: 14px 0 0 0;
    .fileName {
      max-width: 540px;
      background-color: #1c75bc;
      line-height: 19px;
      border-radius: 14px;
      padding: 7px 10px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .onboardingButton {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
