.statusDropdownList {
  position: absolute;
  z-index: 1000;
  border-radius: 4px;
  border: 0.5px solid #e9e9e9;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 14px;
  .itemList {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0;
  }
  button {
    margin-top: 10px;
    width: 100%;
    border-radius: 5px;
    background: #1c75bc;
    color: #fff;
  }
}

