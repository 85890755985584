/* .ZeroPadding .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] .MuiAutocomplete-input {
    padding: 0 !important;
    width:0 !important;
} */

.DefaultPadding
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
  .MuiAutocomplete-input {
  padding: 4.5px 4px !important;
}

.Mui-focused
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
  .MuiAutocomplete-input {
  width: 0 !important;
}

.MuiChip-root {
  border-radius: 0 !important;
  background: #fafafa !important;
  border: 1px solid #e8e8e8 !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 0 !important;
}

.DefaultPadding .MuiInputAdornment-positionStart {
  position: absolute !important;
  right: 5px !important;
}

.disableStyle .MuiInputBase-root.Mui-disabled {
  background: #f6f6f6;
}
