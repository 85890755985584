.mainBox {
  background-color: white;
}

.BackNavigator {
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}
.InputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5rem;
  margin-left: 38px;
  grid-row-gap: 54px;
  margin-left: 60px;
  margin-right: 60px;
  .InputBox {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    p {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin-top: -5px;
    }
  }
}

.InputContainer2 {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 5rem;
  margin-left: 38px;
  grid-row-gap: 54px;
  margin-left: 60px;
  margin-right: 60px;
  margin-top: 54px;
  margin-bottom: 60px;
  .InputBox {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    p {
      font-family: "Nunito";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 27px;
      margin-top: -5px;
    }
    .textAreaDesc {
      height: 119px !important;
      padding: 20px;
      font-family: Nunito;
      font-size: 16px;
      border-color: #d1d1d1;
      border-radius: 5px;
    }
    .textAreaDesc:focus {
      outline: none !important;
      border: 2px solid #1c75bc;
    }
  }
}

.formHeading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.formFont {
  font-family: Nunito;
  font-size: 20px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.textComponent {
  input {
    max-height: 7px;
  }
  label {
    top: -7px;
  }
}
.saveCancel {
  // border-top: 1px solid red;
  display: flex;
  justify-content: flex-end;
  position: sticky;
  padding: 30px 60px 25px 0;
  bottom: 0;
  right: 0;
  background: white;
  z-index: 2;
  border-top: 1px solid #b3aeae;
  margin-top: 50px;
}

.requiredField::after {
  content: "*";
  color: red;
}

.lightText {
  color: #757575;
}

.editBtn {
  background: #ffffff;
  border: 1px solid #1c75bc;
  border-radius: 5px;
  height: 43px;
  width: 85px;
  color: #1c75bc;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  position: absolute;
  right: 44px;
  top: 129px;
}
