.optionCircle{
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: 1.5px solid #d1d1d1;
}
.option{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 20px
}

input{
    border: none;
}

.marksContainer{
    position: relative;
}

.dropdownScreenIconMarks{
    position: absolute;
    color: gray;
    top: 15px;
    right: 15px;
}

.questionInput {
    background: #f6f6f6;
    border-radius: 5px;
    padding: 1rem;
    width: 98%;
    color: #2e2e2e;
    border: 0.5px solid transparent #e1e1e1; /* Add this line to ensure the initial border is transparent */
    /* box-shadow: #212121; */
    /* filter: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1)); */
    font-size: 22px;
    font-weight: 400;
}

.questionInput:focus {
    border: 1px solid #1c75bc;
    outline: none; /* Add this line to remove the default focus outline */
}

.screeningQuestionForm{
    border: 1px solid rgba(128, 128, 128, 0.264);
    /* box-shadow: 1px 1px 3px gray; */
    border-radius: 7px;
    width: 98%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    position: relative;
    margin-bottom: 25px;
}

.addOptionBtn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    font-weight: 400;
    width: 173px;
    cursor: pointer;
    background-color: #1c75bc;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 5px;
    /* gap: 18px; */
    margin-top: 10px;
    margin-bottom: 10px;
}

.addOptionBtnText{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    gap: 18px;
    padding: 8px;
    padding-left: 16px;
    padding-right: 16px;
}

.questionHr{
    width: 100%;
    height: 1px;
    background-color: #645f5f25;
}

.closeBtn{
    display: grid;
    place-items: center;
    cursor: pointer;
}




.screeningBtns>div>div>img{
    width: 26px;
}

.screeningBtns>div>div{
    cursor: pointer;
}

.answerInput{
    padding: 1rem;
    background-color: #ffff;
    border-radius: 5.5px;
    color: #2e2e2e;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    width: 261px;
    border: 1px solid #e2e2e2; /* Add this line to ensure the initial border is transparent */
}

.answerInput:focus {
    border: 1px solid #1c75bc;
    outline: none; /* Add this line to remove the default focus outline */
}

.answerContainer{
    position: relative;
}

.dropdownScreenIcon{
    position: absolute;
    color: gray;
    top: 15px;
    right: 15px;
}

.answersList{
    padding: 1rem;
    border: 1px solid rgba(128, 128, 128, 0.264);
    border-radius: 5.5px;
    position: absolute;
    margin-top: 10px;
    width: 261px;
    z-index: 3;
    background: #fff;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
}

.bge6{
    background-color: #fff;
    border: 1px solid #645f5f25;
    color: #2e2e2e;

}


.answersListOption{
    width: 100%;
    padding-bottom: 5px;
    color: #2e2e2e;
}

.otherFieldsScreening{
    display: flex;
    justify-content: space-between;
}

.marksInput{
    padding: 1rem;
    border-radius: 5.5px;
    border: 1px solid rgba(128, 128, 128, 0.264);
    cursor: pointer;
    background-color: #fff;
    font-weight: 500;
    font-size: 18px;
    width: 261px;
}

.marksInput:focus {
    border: 1px solid #1c75bc;
    outline: none; /* Add this line to remove the default focus outline */
}

.marksList{
    padding: 1rem;
    border: 1px solid rgba(128, 128, 128, 0.264);
    width: 261px;
    border-radius: 5.5px;
    position: absolute;
    margin-top: 10px;
    z-index: 3;
    background: #fff;
    font-size: 18px;
    font-weight: 500;
}

.marksListOption{
    width: 100%;
    padding-bottom: 5px;
    cursor: pointer;
}

.mandatory{
    background-color: #80808042;
    width: 50px;
    height: 18px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.mandatoryActive{
    background-color: #1c75bc;
}

.mandatoryTrue{
    background-color: #fff;
    border: 0.5px solid rgba(116, 112, 112, 0.411);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    transition: 0.5s;
}

.mandatoryFalse{
    right: 0;
}

.error{
    color: red;
    padding: 1rem;
    font-size: small;
}

.screeningBtns{
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 20px;
    /* border: 1px solid gray; */
    border-radius: 12.5px;
    color: #212121a9;
}

.mandatorySection{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 20px;
    font-weight: 500;
}

.verticalHr{
    width: 1px;
    height: 30px;
    background-color: #212121a9;
}

.otherFieldsInputs{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.optionInput{
    background-color: #fff;
    border: 1px solid transparent; /* Add this line to ensure the initial border is transparent */
    padding: 0.5rem;
    font-weight: 500;
    font-size: 20px;
}

.w1200{
    width: 1200px;
}

.w640{
    width: 640px;
}

.w320{
    width: 320px;
}

.w200{
    width: 220px;
}

.w120{
    width: 120px;
}

.optionInput:focus{
    border: 1px solid transparent;
    outline: none;
}

.fieldsInputs{
    display: flex;
    gap: 15px;
    align-items: center;
}

.hidden{
    display: none;
}

.validationScreeningErr{
    color: red;
    font-size: 18px;
}

.disableBG{
    background-color: #e2e2e2;
}

.optionInput.underline {
    border-bottom: 2px dotted #000; /* Adjust the color as needed */
    outline: none;
}

.questionInputTag.underline {
  border-bottom: 2px dotted #000; /* Adjust the color as needed */
  outline: none;
}


.questionInputTag{
    font-size: 22px;
    font-weight: 400;
    background: #f6f6f6;
}