.tableContainer {
  table {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: separate;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  tr td:last-child {
    text-align: right;
  }
  th,
  td {
    padding: 12px;
    vertical-align: top;
    border-left: 1px solid #ddd !important;
  }
  td {
    border-top: 1px solid #ddd;
  }
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
}
.datePicker{
  width: 180px;
  margin-top: 8px !important;
  // margin-right: 20px !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.filtersContainer{
  justify-content: space-evenly;
  align-items: flex-start;
  >div{
    margin: 30px;
  }
}
.buttonGroup{
  button{
      border-radius: 0;
      border: 1px solid #A3A3A3;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #2E2E2E;
  }
  .buttonSelected{
      background-color: #1c75c0;
      color: #ffffff;
  }
}
.fileName{
  max-width: 540px;
  background-color: #1C75BC;
  line-height: 19px;
  border-radius: 14px;
  padding: 7px 10px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.outlinedClients{
  padding: 7px 12px;
  border-radius: 0;
}

.HoldSwitchContainer{
  justify-content: center;
  .holdButton{
    &:hover{
      cursor: pointer;
    }
    border: none;
    padding: .5rem 1rem;
    border-radius: .5rem;
    color: white;
    background-color: green;
    // font-size: 1.5rem;
  }
  .onHold{
    background-color: red;
  }
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin-bottom: 30px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  // margin-right: 30px;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}
.downloadTemplate{
  span{
    color: #1c75bc;
    text-decoration: underline;
  }
}
.filterButton {
  border: 1px solid #1c75bc;
  color: #1c75bc;
  font-size: 16px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  padding: 11px;
  border-radius: 6.486px;
  &:hover {
    cursor: pointer;
  }
}
