.heading-nw{
font-weight: bold;
font-size: 24px;
color:  #1C75BC;
}
.basic-margin{
    margin-top: 40px;
    margin-left: 85px;
}
.level{
    color:  #1C75BC;
    font-weight: 600;
    
}

.pads{
    
    padding-left: 96px;
    padding-top: 24px;

}

.square{
    width: 100px;
    height: 100px;
    background: #f5f5f5;
}

.m30{
    margin-top: 30px;
}
.hw100{
    width: 100px;
    height: 100px;
}
.mr85{
    margin-right: 85px;
}
.red{
color:red !important
}

.mustHaveConfigflex{
    display: flex;
    width: 140px;
    align-items: center;
    justify-content: space-between;
    color: #2E2E2E;
}

.mustHaveConfig{
    background-color: rgba(231, 231, 231, 0.849);
    width: 40px;
    height: 14px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    cursor: not-allowed;
}

.mustHaveConfigActive{
    background-color: rgb(231, 231, 231,0.849);
}

.mustHaveBtnConfig{
    background-color: rgb(250, 250, 250,0.8);
    /* border: 0.5px solid rgba(116, 112, 112, 0.411); */
    box-shadow: 1px 1px 5px gray;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    position: absolute;
    transition: 0.5s;
}

.mustHaveBtnActive{
    right: 0;
    background-color: rgb(166, 166, 166,0.8);
}

.eligibilityTable {
    border-collapse: separate;
    border-spacing: 0 10px; /* Adjust this value to change the vertical spacing */
}
.eligibilityTable td {
    padding: 20px; /* Add padding to the cells */
}