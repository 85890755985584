.tableContainer {
  table {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: separate;
    border-left: 0;
    border-radius: 4px;
    border-spacing: 0px;
  }
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    border-collapse: separate;
  }
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
  }
  tr td:last-child {
    text-align: right;
  }
  th,
  td {
    padding: 12px;
    vertical-align: top;
    border-right: 1px solid #ddd !important;
  }
  td {
    border-top: 1px solid #ddd;
  }
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }
}
.PrimaryColorButton{
  background-color: #2475bc !important;
}
.datePicker{
  width: 180px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker:first-child{
  margin-right: 20px;
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 440px;
  justify-content: center;
  img{
    width: 130px;
    margin: 0px auto 30px auto;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  margin-right: 30px;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}
.searchButtonDiv1{
  display: flex;
  margin-left: -48px;
  .closeButton{
    height: 40px;
  }
  .noVisibility{
    visibility: hidden;
  }
}

.blueOutlinedButton{
  color: #1c75c0;
  text-transform: uppercase;
  border: 1px solid #1c75c0;
  padding: 10px;
  font-weight: bold;
  position:relative;

  &:hover{
      cursor: pointer;
      transform: translateY(-2px);
  }
  
  &:focus{
      outline: unset;
  }
  &:active{
      transform: translateY(-1px);

  }

}

.actionSelect{
  padding: 9px 35px 9px 9px !important;
}

.filterButton{
  border: none;
  color: #1C75BC;
  font-size: 16px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  img{
    margin-right: 5px;
  }
  &:hover{
    cursor: pointer;
  }
}

.FilterContainer{
  max-height: 100vh;
  overflow: auto;
}

.filterCloseArrow{
  margin-right: 20px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
  padding: 5px !important;
}
.FilterHeadingContainer{
  display:flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: flex-end;
}
.filterHeading{
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #1c75c0;
}
.resetFiltersButton{
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #707070 !important;
  text-decoration: underline !important;
  margin-right: 24px !important;
  text-transform: capitalize !important;
  &:hover{
      background-color: unset !important;
      text-decoration: underline;
  }
}
.filterHeadingDivider{
  width: 100%;
  border-top: 1px solid #d5d5d5;
}
.filtersContainer{
  padding: 40px 30px;
}

.singleFilterContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px !important;
  align-items: center;
  padding: 0 20px;
}

.filterTitle{
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
}
.filterButtonDividerContainer{
  position: absolute;
  bottom: 0px;
  background: white;
  padding-bottom: 25px;
}
.fileName{
  max-width: 540px;
  background-color: #1C75BC;
  line-height: 19px;
  border-radius: 14px;
  padding: 7px 10px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 400;
}
.summaryText{
  color:#1c75bc;
  font-weight: 600;
  font-size: 22px;
}
.locationGrid{
  overflow-y: auto;
  max-height: 295px;
  font-size: 18px;
  >div{
    border-bottom:1px solid #C7C7C7;
    padding: 20px 0;
  }
  >div:last-child{
    border:none;
  }
}