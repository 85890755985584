.heading-nw{
    font-weight: bold;
    font-size: 24px;
    color:  #1C75BC;
    }
    .basic-margin{
        margin-top: 40px;
        margin-left: 85px;
    }
    .level{
        color:  #1C75BC;
        font-weight: 600;
        
    }
    
    .pads{
        
        padding-left: 96px;
        padding-top: 24px;
    
    }
    
    .square{
        width: 100px;
        height: 100px;
        background: white;
    }
    
    .m30{
        margin-top: 30px;
    }
    .hw100{
        width: 100px;
        height: 100px;
    }
    .mr85{
        margin-right: 85px;
    }
    .red{
    color:red !important
    }
    
    .mainBoxWide{
        width: 100%;
        box-sizing: border-box;
        padding: 2rem;
        padding-bottom: 6rem;
        background-color: white;
    }
    
    .fieldInputsAge{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    
    .fieldInputs{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        align-items: center;
    }
    
    .fieldBox{
        padding: 1rem;
    }
    
    .ageInputs {
    
        display: flex;
        align-items: center;
        justify-content: space-around;
        box-sizing: border-box;
        gap: 20px;
    }
    
    .infoBtn{
        position: relative;
        display: flex;
    }

    .infoBtn:first-child{
        cursor: pointer;
    }

    .infoArrow{
        position: absolute;
        width: 20px;
        height: 20px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        background-color: #E9E9E9;
        top: -10px;
        left: 5px;
        z-index: 200;
    }

    .InfoBox{
        position: absolute;
        top: 40px;
        z-index: 200;
        background-color: #E9E9E9;
        left: 0;
        font-size: 14px;
        font-weight: 400;
        border-radius: 6px;
        width : 213px;
        padding: 1rem;
        color: #2E2E2E;
        /* box-shadow: 1px 1px 5px rgb(182, 181, 181); */
    }

    .InfoBox3rd{
        padding-bottom: 12px;
    }
    
    .bg-color#eeeeee{
        background-color: #ffffff;
        height: 100vh;
    }
    .ageInputs>div{
        display: flex;
        gap: 5px;
        align-items: center;
    }
    
    .ageInputs>div>input{
        display: flex;
        border: 1px solid rgba(128, 128, 128, 0.482);
        padding: 0.5rem;
        background-color: white;
    }
    
    .mustHaveToggleBtn{
        display: flex;
        /* padding: 1rem; */
        padding-bottom: 0;
        width: 500px;
        gap: 10px;
        justify-content: center;
        align-items: center;
    
    }
    
    .mustHave{
        background-color: rgba(128, 128, 128, 0.257);
        width: 40px;
        height: 14px;
        border-radius: 50px;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    
    .mustHaveToggle{
        background-color: #fff;
        /* border: 0.5px solid rgba(116, 112, 112, 0.411); */
        box-shadow: 1px 1px 5px gray;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        position: absolute;
        transition: 0.5s;
    }
    
    .active{
        background-color: rgba(28, 117, 188, 0.27);
    }
    
    .toggleActive{
        right: 0;
        background-color: rgba(28, 117, 188);
    }
    
    .overFlow-x-hidden{
        overflow-x: hidden;
    }
    
    .horizontalRuleFull{
        height: 1px;
        margin-top: 25px;
        margin-bottom: 25px;
        width: 100%;
        background-color: #80808063;
    }

    .ErrRed{
        color: red;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        padding-bottom: rem;
    }