.searchBarMain {
  width: 100%;
  .searchButtonDiv {
    display: flex;
    margin-left: -135px;
    z-index: 100;
    .closeButton {
      margin-right: 8px;
      padding: 0 4px;
      height: 30px;
      margin-top: 3px;
    }
    .noVisibility {
      visibility: hidden;
    }
    .searchButton {
      border: 0;
      background-color: #1c75bc;
      color: white;
      width: 100px;
      font-size: 17px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: 35px;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: 0;
      }
    }
  }
  .searchInstruction {
    margin-top: 5px;
  }
  .searchInstructionError {
    display: flex;
    margin: 6px;
  }
}

.searchBar {
  width: 100%;
  .searchButtonDiv {
    display: flex;
    margin-left: -90px;
    z-index: 100;
    .closeButton {
      margin-right: 8px;
      padding: 0 4px;
      height: 30px;
      margin-top: 3px;
    }
    .noVisibility {
      visibility: hidden;
    }
    .searchButton {
      border: 0;
      background-color: #1c75bc;
      color: white;
      width: 100px;
      font-size: 17px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: 35px;
      &:hover {
        cursor: pointer;
      }
      &:focus {
        outline: 0;
      }
    }
  }
  .searchInstruction {
    margin-top: 5px;
  }
  .searchInstructionError {
    display: flex;
    margin: 6px;
  }

  // =========================================
  .leadSearchBar {
    display: flex;
    .actionSelect {
      padding: 9px 35px 9px 9px !important;
    }
    .closeButton {
      margin-right: 8px;
      padding: 0 4px;
      height: 30px;
      margin-top: 3px;
    }
    .noVisibility {
      visibility: hidden;
    }
    .searchButtonDisable {
      border: 0;
      background-color: #b4b4b4;
      color: white;
      font-size: 17px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: 35px;
      &:focus {
        outline: 0;
      }
    }
    .searchButton {
      border: 0;
      background-color: #1c75bc;
      color: white;
      font-size: 17px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: 35px;
      &:focus {
        outline: 0;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.actionFormControl{
  .actionSelect {
    padding: 9px 35px 10px 9px !important;
  }
}
