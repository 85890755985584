.root {
  flex-grow: 1;
  margin-bottom: 80px;
}
.Artboard3 {
  width: 80px;
  height: 68.7px;
  object-fit: contain;
}
.version {
  margin-top: auto;
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: #aaaaaa;
}
.NavbarBG {
  height: 80px;
  margin: 0 0 45px;
  padding: 7.7px 46px 3.7px 39px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #1c75bc !important;
}
.logout {
  margin-left: auto !important;
}
.drawer {
  width: 240px;
}
.drawerPaper {
  width: 240px;
}
.drawerHeader {
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: flex-end;
}
.activeRoute {
  background-color: #1c75bc;
  color: white;
}
.activeRoute:hover {
  background-color: #1265a8;
}
.routeLink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.84);
}
.listIcon {
  height: 24px;
}
