.screeningQuestionView{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Nunito',sans-serif;
    background-color: #fff;
}

.screeningQuestionContainer{
    width: 100%;
    height: 100%;
    padding: 2rem;
    padding-left: 60px;
    padding-right: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.screeningSubNav{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.screeningSubNavLeft{
    font-family: 'Nunito',sans-serif;
    display: flex;
    color: #2e2e2e;
    gap: 0.7rem;
    align-items: center;
    font-weight: 600;
    font-size: 24px;
}

.screeningSubNavRightBtn{
    color: #1c75bc;
    height: 100%;
    border: 1.5px solid #1c75bc;
    border-radius: 5px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.screeningSubNavRightBtn>div{
    color: #1c75bc;
    font-weight: 500;
    font-size: 16px;
}

.screeningSubNavRight{
    display: flex;
    height: 100%;
    gap: 25px;
}

.screeningEmptyBody{
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
}

.emptyScreeningImage{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
}

.emptyScreeningText{
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    color: #2e2e2e;
    padding-top: 20px;
}

.screeningQuestionBody{
    width: 100%;
}

.screeningSubNavRightBtn svg{
    width: 32px;
}

.payloadSaveForm{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 35vh;
}

.payloadInputs{
    display: flex;
    align-items: center;
    gap: 15px;
}

.passingMarks{
    padding: 0.7rem;
    padding-left: 16px;
    padding-right: 16px;
    width: 177px;
    font-size: 20px;
    border: 0.5px solid #bfbfbfa9;
    border-radius: 5.5px;
    text-align: center;
    background-color: #fff;
}
.totalMarks{
    padding: 0.7rem;
    padding-left: 16px;
    padding-right: 16px;
    width: 105px;
    font-size: 20px;
    background-color: rgba(191, 191, 191, 0.127);
    border-radius: 5.5px;
    border: 0.5px solid #f0f0f0;
    text-align: center;
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.payloadInputsHead{
    color: #2e2e2e;
}

.payloadSave{
    padding : 0.7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color : white;
    border:1px solid #1c75bc;
    font-size: 14px;
    font-weight: 500;
    background-color: #1c75bc;
    border-radius: 3.5px;
    transition: 0.5s;
    cursor: pointer;
    box-shadow: 3px 3px 5px rgb(173, 196, 230);
}

.payloadCancel{
    padding : 0.7rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color : #1c75bc;
    border:1px solid #1c75bc;
    background-color: white;
    border-radius: 3.5px;
    transition: 0.5s;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
}

.payloadCancel:hover{
    color: white;
    background-color: #1c75bc;
}

.actionBtnScreening{
    position: fixed;
    bottom: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    background-color: #fff; /* You can change this color as needed */
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    /* padding-right: 4rem; */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    gap: 20px;
    box-sizing: border-box;
}

.actionBtnsMAin{
    display: flex;
    gap: 20px;
}

.actionBtnScreeningBetween{
    justify-content: space-between;
}

.payloadExtraBtn{
    width: 60px;
    height: 30px;
}

.passingMarks:focus {
    background-color: #eeeeee;
    border: 1px solid #1c75bc;
    outline: none;
}

.screeningSubNavRightBtn>svg{
    width: 32px;
    height: 32px;
}

.screeningBackBtn>svg{
    color: #2e2e2e;
    padding-top: 10px;
    width: 32px;
}

.reapplyToggle{
    display: flex;
    align-items: center;
    padding: 1rem;
    gap: 12px;
    font-size: 18px;
    font-weight: 500;
    color: #2e2e2e;
}

.reapplyToggleSwitch{
    background-color: #80808042;
    width: 50px;
    height: 18px;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.reapplyToggleSwitchOn{
    background-color: #1c75bc;
}

.reapplyToggleBtn{
    background-color: #fff;
    border: 0.5px solid rgba(116, 112, 112, 0.411);
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: absolute;
    transition: 0.5s;
}

.reapplyToggleBtnOn{
    right: 0;
}

.payloadInputs{
    font-size: 18px;
    font-weight: 500;
}


/* Hide up/down arrows for Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide up/down arrows for Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Prevent scrolling on number input */
input[type=number]:hover,
input[type=number]:focus {
  -moz-appearance: textfield;
}
